import axios from 'axios';
import router from './router';

export default function setup() {
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
    function (err) {
      return Promise.reject(err);
    });
  axios.interceptors.response.use((response) => {
    return response;
  },
    function (error) {
      if (error.response.status == 401) {
        if (localStorage.getItem("token")) {
          localStorage.clear();
          showMessage(error.response.data.message, 'warning')
          router.push("/login");
        }
        return Promise.reject(error)
      } else if (error.response.status == 409) {
        localStorage.clear();
        showMessage(error.response.data.message, 'warning')
        router.push("/register");
        return Promise.reject(error)
      }

      else {
        return Promise.reject(error);
      }
    });
}

const showMessage = (msg, type) => {
  const toast = window.Swal.mixin({ toast: true, position: 'top', showConfirmButton: false, timer: 3000 });
  toast.fire({ icon: type, title: msg, padding: '10px 20px' });
};

