import axios from 'axios';

// import {createStore} from 'vuex';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    software: async ({ commit }, getData) => {
        return await axios
            .post('/software', getData)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },
    getSoftware: async ({ commit, dispatch }, getData) => {
        return await axios
            .get(`/software?query=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },

    getSoftwareLogs: async ({ commit, dispatch }, getData) => {
        return await axios
            .get(`/software/logs?query=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },

    updateSoftware: async ({ commit, dispatch }, getData) => {
        return await axios
            .put(`/software/${getData.id}`, getData)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    editSoftware: async ({ commit }, getData) => {
        return await axios
            .get(`/software/${getData.id}`)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    deleteSoftware: async ({ commit }, id) => {
        return await axios
            .delete(`/software/${id}`)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
