<template>
  <div class="layout-px-spacing">
    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
        <div class="panel br-6 p-0">
          <router-link to="/releases-main-form"><button type="button" class="btn btn-primary add-btn1">ADD</button>
          </router-link>

          <div class="custom-table">
            <v-server-table :columns="columns" :options="releasesMain" :key="recall">

              <template #software_id="props">
                <span class="text-uppercase">{{ props.row.software.name }}</span>
              </template>

              <template #actions="props">
                <div class="me-2 custom-dropdown dropdown btn-group">
                  <router-link class="btn btn-icon-only"
                    :to="{ name: 'edit-releases-main-form', params: { id: props.row.id } }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" margin-right="20" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-edit">
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </router-link>
                  <button class="btn btn-icon-only" @click="deleteReleasesFun(props.row.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" margin-left="20" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-trash">
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                      </path>
                    </svg>
                  </button>
                </div>
              </template>
            </v-server-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
const store = useStore();
const columns = ref(['software_id', 'version', 'description', 'filename', 'filepath', 'created_at', 'actions']);
const recall = ref(0);
const software_id_option = ref([]);
const releasesMain = ref({
  headings:
  {
    software_id: 'Software Name',
    filename: 'Filename',
    filepath: 'Filepath',
    created_at: 'Created At'
  },
  perPage: 5,
  perPageValues: [5, 10, 25],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['software_id', 'version', 'script', 'destination_folder', 'file'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5 },
  texts: {
    count: 'Showing {from} to {to} of {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'software_id';
    return store.dispatch(`getReleases`, pagination).then((res) => {
      return { data: res.data.data.data, count: res.data.data.total };
    });
  },
});

function deleteReleasesFun(id) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deleteReleases', id).then((response) => {
        if (response.status == 200) {
          recall.value += 1;
          showAlertMessage(response.data.message, 'success');
        } else {
          showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
}
const showAlertMessage = (msg, type) => {
  const toast = window.Swal.mixin({ toast: true, position: 'top', showConfirmButton: false, timer: 3000 });
  toast.fire({ icon: type, title: msg, padding: '10px 20px' });
};
</script>

<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}
</style>