<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-md-12" style="margin-bottom: 24px">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <h3 v-if="$route.params.id"><strong>Edit Customer</strong></h3>
                        <h3 v-else><strong>Add Customer</strong></h3>
                    </div>
                    <div class="panel-body">
                        <Form @submit="customerSubmit" :validation-schema="customerSchema" v-slot="values"
                            :initial-values="customerFormData">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="customer_name">Customer Name</label>
                                    <Field type="text" id="customer_name" class="form-control" name="customer_name" />
                                    <ErrorMessage class="error_alert" name="customer_name" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="company_name">Company Name</label>
                                    <Field type="text" id="company_name" class="form-control" name="company_name" />
                                    <ErrorMessage class="error_alert" name="company_name" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="contact_no">Contact Number</label>
                                    <Field type="text" id="contact_no" class="form-control" name="contact_no" />
                                    <ErrorMessage class="error_alert" name="contact_no" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="country_id">Country</label>
                                    <Field name="country_id" as="select" class="form-control"
                                        @change="onCountryChange()" v-model="customerFormData.country_id">
                                        <option value="" disabled selected>Select State</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">
                                            {{ country.name }}</option>
                                    </Field>
                                    <ErrorMessage class="error_alert" name="country_id" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="state_id">State</label>
                                    <Field name="state_id" as="select" class="form-control" @change="onStateChange()"
                                        v-model="customerFormData.state_id">
                                        <option value="" disabled selected>Select State</option>
                                        <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name
                                            }}
                                        </option>
                                    </Field>
                                    <ErrorMessage class="error_alert" name="state_id" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="city_id">State</label>
                                    <Field name="city_id" as="select" class="form-control">
                                        <option value="" disabled selected>Select City</option>
                                        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}
                                        </option>
                                    </Field>
                                    <ErrorMessage class="error_alert" name="city_id" />
                                </div>

                             
                                <div class="form-group col-md-4">
                                    <label for="purchase_date">Purchase Date</label>
                                    <Field type="date" v-model="customerFormData.purchase_date" class="form-control"
                                        name="purchase_date" />
                                    <ErrorMessage class="error_alert" name="customerFormData.purchase_date" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="expiry_date">Expiry Date</label>
                                    <Field type="date" id="customerFormData.expiry_date" class="form-control"
                                        name="expiry_date" v-model="customerFormData.expiry_date" />
                                    <ErrorMessage class="error_alert" name="customerFormData.expiry_date" />
                                </div>


                                <div class="form-group col-md-4">
                                    <label for="software_id">Software</label>
                                    <Field name="software_id" as="select" class="form-control">
                                        <option value="" disabled selected>Select Software</option>
                                        <option v-for="software in softwares" :key="software.id" :value="software.id">
                                            {{ software.name }}</option>
                                    </Field>
                                    <ErrorMessage class="error_alert" name="software_id" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="software_variant">Software Variant</label>
                                    <Field name="software_variant" as="select" class="form-control">
                                        <option value="" disabled selected>Select Software Variant</option>
                                        <option v-for="software in softwares_variant" :key="software.id" :value="software.id">
                                            {{ software.name }}</option>
                                    </Field>
                                    <ErrorMessage class="error_alert" name="software_variant" />
                                </div>


                                <div class="form-group col-md-4">
                                    <label for="device_id">Devices</label>
                                    <Field name="device_id" as="select" class="form-control">
                                        <option value="" disabled selected>Select Device</option>
                                        <option v-for="device in devices" :key="device.id" :value="device.id">
                                            {{ device.device_name }}</option>
                                    </Field>
                                    <ErrorMessage class="error_alert" name="device_id" />
                                </div>


                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <router-link type="button" to="/customers-main-table" class="btn btn-danger mr-2"
                                        style="margin-right:20px;">BACK</router-link>
                                    <button v-if="$route.params.id" :disabled="loading" type="submit"
                                        class="btn btn-primary">
                                        {{ loading ? 'UPDATING...' : 'UPDATE' }}
                                    </button>
                                    <button v-else :disabled="loading" type="submit" class="btn btn-primary">
                                        {{ loading ? 'SAVING...' : 'SAVE' }}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue';
import { defineRule, Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();
const param_id = ref([]);
const errors = ref({ tag: [] });
const softwares_variant = ref([{id: 'standard', name: 'Standard'}, {id: 'premium', name: 'Premium'}])
const customerFormData = ref({
    customer_name: null,
    company_name: null,
    country_id: null,
    state_id: null,
    city_id: null,
    contact_no: null,
    purchase_date: null,
    expiry_date: null,
    software_id: null,
    device_id: null,
    software_variant: null
});


const countries = ref([]);
const states = ref([]);
const cities = ref([]);
const devices = ref([]);
const softwares = ref([]);
const loading = ref(false)

const defaultCountryId = '101';
const customerSchema = yup.object().shape({
    customer_name: yup.string().required('This field is required.'),
    company_name: yup.string().required('This field is required.'),
    contact_no: yup.string().required('This field is required.').matches(/^([0-9\s\-\+\(\)]*)$/, 'Invalid Format'),
    country_id: yup.string().required('This field is required.'),
    state_id: yup.string().required('This field is required.'),
    city_id: yup.string().required('This field is required.'),
    purchase_date: yup.string().required('This field is required.'),
    expiry_date: yup.string().required('This field is required.'),
    software_id: yup.string().required('This field is required.'),
    software_variant: yup.string().required('This field is required.'),
    device_id: yup.string().required('This field is required.')
});

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const getExpiryDate = (startDate) => {
    const currentDate = new Date(startDate);
    currentDate.setFullYear(currentDate.getFullYear() + 3);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};
onBeforeMount(async () => {
    try {

        const countriesResponse = await store.dispatch('getCountry', {});
        countries.value = countriesResponse.data.data;

        const softwareResponse = await store.dispatch('getSoftwareList', {});
        softwares.value = softwareResponse.data;

        const deviceResponse = await store.dispatch('getDeviceList', {});
        devices.value = deviceResponse.data;

        customerFormData.value.purchase_date = getCurrentDate();
        customerFormData.value.expiry_date = getExpiryDate(customerFormData.value.purchase_date);

        customerFormData.value.country_id = defaultCountryId;
        onCountryChange();

        if (route.params.id) {

            const response = await store.dispatch('editCustomer', { id: route.params.id });
            if (response.status === 200) {
                customerFormData.value = response.data.data;
                customerFormData.value.country_id = response.data.data?.country?.id
                onCountryChange()

                customerFormData.value.state_id = response.data.data?.state?.id
                onStateChange()

                customerFormData.value.city_id = response.data.data?.city?.id
                customerFormData.value.software_id = response.data.data?.software?.id
                customerFormData.value.device_id = response.data.data?.device?.id
            }
        }
    }

    catch (error) {
        console.error("Error loading data:", error);
    }
});


function customerSubmit(values) {
    loading.value = true;

    if (!route.params.id) {

        store.dispatch('addCustomer', values).then((res) => {
            if (res.data.errors) {
                errors.value = res.data.errors;
            }

            if (res.status == 200) {
                showMessage(res.data.message, 'success');
                router.push('/customers-main-table');
            }
            else {
                showMessage(res.data.message, 'warning');
            }
        });
    }
    else {
        values['id'] = route.params.id;
        store.dispatch('updateCustomer', values).then((res) => {
            if (res.data.errors) {
                errors.value = res.data.errors;
            }

            if (res.status == 200) {
                showMessage(res.data.message, 'success');
                router.push('/customers-main-table');
            }
            else {
                showMessage(res.data.message, 'warning');
            }
        });
    }
    loading.value = false;
}
const onCountryChange = async () => {
    try {
        const statesResponse = await store.dispatch('getStates', { id: customerFormData.value.country_id });
        states.value = statesResponse.data;
    } catch (error) {
        console.error('An error occurred:', error);
        throw error;
    }
};

const onStateChange = async () => {
    try {
        const citiesResponse = await store.dispatch('getCities', { id: customerFormData.value.state_id });
        cities.value = citiesResponse.data;
    } catch (error) {
        console.error('An error occurred:', error);
        throw error;
    }
};

const showMessage = (msg, type) => {
    const toast = window.Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
    });
    toast.fire({
        icon: type,
        title: msg,
        padding: '10px 20px',
    });
};
</script>

<style>
.tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: none;
    padding: 0 10px;
}

.tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #5C6BC0;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
}

.h23 {
    margin: 20px;
}

.date-pckr {
    background-color: #f1f2f300 !important;
    color: #060818;
}

.m-12 {
    margin: 5px;
}

.btn btn-outline-primary mb-2 me-1 {
    width: 50%;
}
</style>