
<template>
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="panel br-6 p-0">
            <router-link to="/deployment-main-form"><button type="button" class="btn btn-primary add-btn1">ADD</button> </router-link>
            <div class="custom-table">
              <v-server-table :columns="columns" :options="deploymentMain" :key="recall">
                <template #device_id="props">
                  <span class="text-uppercase">{{ props.row.devices.name }}</span>
                </template>
                <template #software_name="props">
                  <span class="text-uppercase">{{ props.row.releases.software.name }}</span>
                </template>
                <template #release_id="props">
                  <span class="text-uppercase">{{ props.row.releases.version }}</span>
                </template>
                <template #status="props">
                  <span class="text-uppercase status-box" v-if="props.row.status==0"> Pending </span>
                <span class="text-uppercase status-box-1 " v-else> Successful </span>
                </template>

                <template #actions="props">
                  <div class="me-2 custom-dropdown dropdown btn-group">
                    <a class="btn dropdown-toggle btn-icon-only" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="width: 24px; height: 24px"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                          <router-link class="links dropdown-item" :to="{ name: 'edit-deployment-main-form', params: { id: props.row.id } }">Edit</router-link>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item" @click="deleteDeviceFun(props.row.id)">Delete</a>
                      </li>
                    </ul>
                    <ul class="dropdown-menu dropdown-menu-end">
                    </ul>
                  </div>
                </template>
              </v-server-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
      <script setup>
  import { onMounted, ref, onBeforeMount } from 'vue';
  import { useStore } from 'vuex';
  import { useMeta } from '@/composables/use-meta';
  import { useRouter, useRoute } from 'vue-router';
  const store = useStore();
  const columns = ref(['software_name','device_id',  'release_id', 'status', 'actions']);
  const recall = ref(0);
  const deploymentMain = ref({
    headings:
    {
      software_name:'Software Name',
      device_id:'Device Name',
      release_id:'Version'

    },
    perPage: 5,
    perPageValues: [5, 10, 25],
    skin: 'table',
    columnsClasses: { actions: 'actions text-center' },
    sortable: ['software_name','device_id', 'release_id', 'status' ],
    sortIcon: {
      base: 'sort-icon-none',
      up: 'sort-icon-asc',
      down: 'sort-icon-desc',
    },
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
      count: 'Showing {from} to {to} of {count}',
      filter: '',
      filterPlaceholder: 'Search...',
      limit: 'Results:',
    },
    resizableColumns: false,
    async requestFunction(data) {
  
      const pagination = data;
      const direction = data.ascending == 1 ? 'asc' : 'desc';
      data.direction = direction;
      data.orderBy = data.orderBy ? data.orderBy : 'device_id';
      return store.dispatch(`getDeployments`, pagination).then((res) => {  // name of the function

        return { data: res.data.data, count: res.data.total };
      });
    },
  });
  
  function deleteDeviceFun(id) {
    new window.Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      padding: '2em',
    }).then((result) => {
      if (result.value) {
        store.dispatch('deleteDeployments', id).then((response) => {
          if (response.status == 200) {
            recall.value += 1;
            showAlertMessage(response.data.message, 'success');
          } else {
            showAlertMessage(response.data.message, 'warning');
          }
        });
      }
    });
  }
  const showAlertMessage = (msg, type) => {
    const toast = window.Swal.mixin({ toast: true, position: 'top', showConfirmButton: false, timer: 3000 });
    toast.fire({ icon: type, title: msg, padding: '10px 20px' });
  };
  </script>
  <style>
  .add-btn1 {
    margin-left: 20px;
    margin-top: 20px;
  }
  .status-box {
    background-color: #df2121;
    color: white;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 0.25rem;
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2);
}

.status-box-1 {
    background-color: #36990bed;
    color: white;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 0.25rem;
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.2);
}



  
  </style>   
  