<template>
    <div class="form full-form auth-cover">
        <div class="form-container">
            <div class="form-form">
                <div class="form-form-wrap">
                    <div class="form-container">
                        <div class="form-content">
                            <h1 class="update_section">
                                <router-link to="/"><span class="brand-name">Upgrade
                                        Junction</span></router-link>
                            </h1>
                            <!-- <p class="signup-link">New Here? <router-link to="/auth/register">Create an account</router-link></p> -->
                            <Form class="mb-4" @submit="loginSubmit" :initial-values="loginFormData"
                                :validation-schema="loginSchema">
                                <div class="form">
                                    <div id="user_id-field" class="field-wrapper input">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <Field type="text" class="form-control" placeholder="Username" name="email" />
                                        <ErrorMessage class="error_alert" name="email" />
                                        <p class="text-danger" v-for="err in errors.email" :key="err.id">{{ err }}</p>
                                    </div>

                                    <div id="password-field" class="field-wrapper input mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock">
                                            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                        </svg>
                                        <Field v-bind:type="passwordFieldType" class="form-control"
                                            placeholder="Password" name="password" v-model="password" />
                                        <ErrorMessage class="error_alert" name="password" />
                                        <p class="text-danger" v-for="err in errors.password" :key="err.id">{{ err }}
                                        </p>
                                        <span class="placeholder_icon"> <i @click="togglePasswordVisibility"
                                                v-bind:class="passwordFieldType === 'password' ? 'far fa-eye-slash' : 'far fa-eye'"></i>
                                        </span>
                                        <link rel="stylesheet"
                                            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
                                            integrity="sha512-UyG5r1H9i1zJ0V0WAjZz+f8dJxlVGyoDw7pojCtOYI8j9z0JqvG95VvLHYfjW8PWE6mKDNj+GjpRHJomM6sng=="
                                            crossorigin="anonymous" referrerpolicy="no-referrer" />
                                    </div>
                                    <div class="d-sm-flex justify-content-between">
                                        <!-- <div class="field-wrapper toggle-pass d-flex align-items-center">
                                            <p class="d-inline-block">Show Password</p>
                                            <label class="switch s-primary mx-2">
                                                <input type="checkbox" class="custom-control-input" checked="" v-model="passwordVisible"  />
                                                <span class="slider round"></span>
                                            </label>
                                        </div> -->

                                        <div class="field-wrapper">
                                            <button type="submit" class="btn btn-primary">Log
                                                In</button>
                                        </div>
                                    </div>

                                    <!-- <div class="field-wrapper text-center keep-logged-in">
                                        <div class="checkbox-outline-primary custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" value="true" id="chkRemember" />
                                            <label class="custom-control-label" for="chkRemember">Keep me logged in</label>
                                        </div>
                                    </div> -->

                                    <!-- <div class="field-wrapper">
                                        <router-link to="/auth/pass-recovery" class="forgot-pass-link">Forgot Password?</router-link>
                                    </div> -->
                                </div>
                            </Form>

                            <p class="terms-conditions">
                                © {{ currentYear }} All Rights Reserved. <a href="https://www.nebero.com/">Nebero
                                    Systems
                                    (P) Ltd </a>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-image">
                <div class="l-image">

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, computed } from 'vue';
import { defineRule, Field, Form, ErrorMessage, FieldArray, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import '@/assets/sass/authentication/auth.scss';
import { useStore } from 'vuex';
const store = useStore();
import '@fortawesome/fontawesome-free/css/all.css';
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const errors = ref({});
const password = ref('');
const currentYear = ref(new Date().getFullYear());
const passwordFieldType = ref("password");
const loginFormData = ref({
    email: '',
    password: '',
});
const loginSchema = yup.object().shape({
    email: yup.string().required('This field is required.'),
    password: yup.string().required('This field is required.'),
});


const btn_disabled = ref(false)
const headers = ref([])

const loginSubmit = (values) => {
    //   const isLoggedIn=localStorage.getItem('token');
    // if(isLoggedIn)
    // {
    //   router.push('/');
    //   return;
    // }

    if(btn_disabled.value) return

    if (!route.params.id) {
        btn_disabled.value = true
        store.dispatch('login', values).then((res) => {
            console.log(res)
            store.commit('toggleLoggedStatus', true);
            const user = res.data.user_id;
            localStorage.setItem('token', res.data.data.token);
            showMessage(res.data.message, 'success');
            router.push('/');
            btn_disabled.value = false
        }).catch(err => {
            btn_disabled.value = false

            console.log(err)
            if (err.response) {
                headers.value = err.response.headers
                if (headers.value['retry-after']) {
                    const timeStamp = headers.value['retry-after']

                    showMessage(convertSecondsToReadableTime(timeStamp), 'warning');
                } else {
                    showMessage(err.response.data.message, 'warning');
                    errors.value = err.response.data.errors;
                }
            } else {
                showMessage(err.message, 'warning');
            }


        });
    }
};

const convertSecondsToReadableTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `Retry after ${minutes} minute${minutes !== 1 ? 's' : ''} and ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
}


function togglePasswordVisibility() {
    passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password";
}
const showMessage = (msg, type) => {
    const toast = window.Swal.mixin({ toast: true, position: 'top', showConfirmButton: false, timer: 3000 });
    toast.fire({ icon: type, title: msg, padding: '10px 20px' });
};
</script>
<style>
.needs-validation {
    margin-bottom: 13px;
}

.image-src {
    margin-top: 10px;
    margin-right: 10px;
}

.far,
.fa-regular {
    font-weight: 300;
    position: absolute;
}

.update_section {
    margin-bottom: 20px;
}
</style>
