import axios from 'axios';
import router from '../../router';

const state = {
    authData: {},
    userProfile: {},
};
const getters = {
    getUserData(state) {
        return state.authData;
    },
    getUserProfile(state) {
        return state.userProfile;
    },
};
const mutations = {
    SET_USER_DATA(state, payload) {
        state.authData = payload;
    },
    SET_USER_DETAIL(state, payload) {
        state.userProfile = payload;
    },
};
const actions = {
    login: async ({ commit, dispatch }, getData) => {
        return await axios.post('/login', getData).then((resp) => {
            commit('SET_USER_DATA', resp.data);
            return resp;
        }).catch((error) => {
            throw error;
        });
    },

    logout: async ({ commit }) => {
        return await axios
            .delete(`/logout`)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },

    updateProfile: async ({ commit, state, dispatch }, getData) => {
        return await axios
            .put(`/profile`, getData)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    editProfile: async ({ commit }) => {
        return await axios
            .get(`/profile`)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },

    updatePassword: async ({ commit, dispatch }, getData) => {
        return await axios
            .post(`/update_password`, getData)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
