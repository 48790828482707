import axios from 'axios';

// import {createStore} from 'vuex';

 const state= {
};
 const getters={
};
 const mutations = {
};
const actions = {
    
    deployments :async ({ commit }, getData)=>{
        return await axios.post('/deployments', getData).then((resp) => {
            return resp;
        })
            .catch(error => {
                return error.response
            });
    },
    editDeployments: async ({ commit }, getData) => {
        return await axios.get(`/deployments/${getData.id}`).then((res) => {
            return res;
        })
            .catch(error => {
                return error.response
            });
    },
    getDeployments: async ({ commit, dispatch }, getData) => {
        return await axios.get(`/deployments?search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
            return (response);
        })
            .catch(error => {
                return error.response;
            });
    },
    updateDeployments: async ({ commit, dispatch }, getData) => {
        return await axios.put(`/deployments/${getData.id}`, getData).then((res) => {
            return res;
        })
            .catch(error => {
                return error.response
            });
    },
   
    deleteDeployments: async ({ commit }, id) => {
        return await axios.delete(`/deployments/${id}`).then((resp) => {
            return resp;
        })
            .catch(error => {
                return error.response
            });
    },
}



export default {
    state,
    getters,
    mutations,
    actions
}

   
