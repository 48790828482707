import axios from 'axios';

// import {createStore} from 'vuex';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    addCustomer: async ({ commit }, getData) => {
        return await axios
            .post('/customers', getData)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },

    getCustomer: async ({ commit, dispatch }, getData) => {
        return await axios
            .get(`/customers?query=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },

    updateCustomer: async ({ commit, dispatch }, getData) => {
        return await axios
            .put(`/customers/${getData.id}`, getData)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    editCustomer: async ({ commit }, getData) => {
        return await axios
            .get(`/customers/${getData.id}`)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    deleteCustomer: async ({ commit }, id) => {
        return await axios
            .delete(`/customers/${id}`)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },

    getSoftwareList: async ({ commit, dispatch }, getData) => {
        return await axios
            .get(`/software/select`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },
    getDeviceList: async ({ commit, dispatch }, getData) => {
        return await axios
            .get(`/device/select`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },

    getCountry: async ({ commit, dispatch }, getData) => {
        return await axios
            .get(`/countries`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },

    getStates: async ({ commit, dispatch }, getData) => {
        try {
            const response = await axios.get(`/states?id=${getData.id}`);
            return response.data;
        } catch (error) {
            console.error('Error in getStates:', error);
            throw error.response.data;
        }
    },

    getCities: async ({ commit, dispatch }, getData) => {
        try {
            const response = await axios.get(`/cities?id=${getData.id}`);
            return response.data;
        } catch (error) {
            console.error('Error in getCities:', error);
            throw error.response.data;
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
